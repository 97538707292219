.Toastify__toast {
  padding: 0 0 0 1.5rem;
  border-radius: 3px;
}

.Toastify__toast-container--bottom-right {
  bottom: 0.5rem;
  right: 1rem;
  padding: 0;
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: #56ae56;
}

.Toastify__toast--warning {
  background: #eca56c;
}

.Toastify__toast--error {
  background: #d47167;
}

.Toastify__toast-body {
  margin: auto 0;
  -ms-flex: 1;
  flex: 1;
}
