.ReactTable {
  border: none;
}

/* workaround to prevent horizontal scroll from the vertical scroll space  */
.ReactTable .rt-tbody {
  overflow-x: hidden;
}

.ReactTable .rt-thead.-filters input {
  border: none;
  background: none;
  height: auto;
  font-size: 0.8rem;
}

/* workaround to prevent the last row from overflowing into a horizontal scroll */
.ReactTable .rt-th:last-of-type .rt-resizer {
  position: absolute;
  right: 0;
  width: 18px;
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.10);
}

.ReactTable .-pagination {
  border-top: none;
  box-shadow: 0 0 4px 0 rgba(0,0,0,.1);
}

.ReactTable .rt-thead.-filters input {
  height: 32px;
}